import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import PageHead from "@Components/PageHead";

function ThankYou() {
  return (
    <div>
      <PageHead>
        <React.Fragment>
          <h2 className="head-title">
            Thank <span>You</span>
          </h2>
          <HomeCallToAction to="/">Home</HomeCallToAction>
        </React.Fragment>
      </PageHead>
    </div>
  );
}

export default ThankYou;

const HomeCallToAction = styled(Link)`
  font-size: 2rem;
  text-decoration: none;
  margin-top: 30rem;
  padding: 0.5rem 2rem;
  color: #fff;
  border: 2px solid #00e0d0;
  transition: all 0.2s ease;
  &:hover {
    background-color: #00e0d0;
  }
`;
